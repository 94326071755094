/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/) OVERRIDE FILE
 * Author - Brendan Smith
 * These values are configurable variables from the _variables.scss file, over written by this file.
 * This file is named _bootstrap-overrides.scss - the leading underscore stops SASS from compiling this file.
 * When starting a new theme - it might be best to refresh the variables with the default values from the 
 * Bootstrap _variables.scss file.
 */

// Import web fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');
// Overrides next!
// 
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #cecece !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #464646 !default;
$gray-900: #535363 !default;
//
//
$black: #000 !default;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #a65400 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #396e12 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
//
//
$primary: $gray-800 !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $orange !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
//
//
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: true !default;
$enable-responsive-font-sizes: false !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;
//
//
$body-bg: $white !default;
$body-color: $dark !default;
//
//
$link-color: $primary !default;
$link-decoration: underline !default;
$link-hover-color: darken($link-color,
15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;
//
// Radius
$border-radius: .15rem !default;
$border-radius-lg: .2rem !default;
$border-radius-sm: .1.5rem !default;
//
//
// Typography
$font-family-sans-serif-body: 'Roboto',
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;
$font-family-sans-serif: 'Roboto',
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;
$font-family-sans-serif-buttons: 'Roboto',
Helvetica,
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular,
Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace !default;
//
//
$font-family-base: $font-family-sans-serif-body !default;
$font-size-base: 1.05rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * .875 !default;
$font-weight-lighter: lighter !default;
$font-weight-light: 200 !default;
$font-weight-normal: 300 !default;
$font-weight-bold: 500 !default;
$font-weight-bolder: bolder !default;
$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;
//
//
$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.6 !default;
$h3-font-size: $font-size-base * 1.4 !default;
$h4-font-size: $font-size-base * 1.3 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
//
//
$headings-font-family: $font-family-sans-serif-body !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: $gray-800 !default;
//
//
$input-btn-padding-y: .375rem !default;
$input-btn-padding-x: .75rem !default;
$input-btn-font-family: $font-family-sans-serif-buttons !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;
//